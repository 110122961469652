@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  /* font-family: CormorantInfant; */
  /* src: url('./fonts/CormorantInfant-Medium.ttf'); */
}

@layer base {
  html {
    /* font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif; */
    font-family: ui-serif, serif;
    /* font-family: CormorantInfant, ui-serif, serif; */
  }
}

.main-bgcolor {
  background: rgb(255, 247, 238);
}


.bg-cat {
  background-image: url("./svgs/cat.svg");
  /* background-size: 24px 24px; */
  background-repeat: no-repeat;
  background-position: right 30px top 30px;
}

.bg-onboard {
  background-image: url("./svgs/cat-onboard-3.svg");
  background-repeat: no-repeat;
  background-position: top 100px center;
  background-size: 60%;
}

.bg-onboard-form {
  background-image: url("./svgs/cat-onboard-4.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 100px center;
}

html {
  scroll-behavior: smooth;
}

body {
  background: rgb(255, 247, 238);
  /* max-width: 480px;
  margin: 0 auto;
  overflow-x: hidden; */
}


/* image list */
.product-images img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-images img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* google review section */
.google-reviews {
  padding: 20px;
  border-radius: 8px;
}


.google-reviews .review-card {
  transition: box-shadow 0.3s ease;
}

.google-reviews .review-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
/* end of google review section */